<template>
  <div>

    <HeaderMain />

    <!-- ТВОЙ ПОМОЩНИК НА КАЖДЫЙ ДЕНЬ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: var(--accent);
               position: relative;
               overflow: hidden;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading" style="color: #fff;">Твой помощник<br>на каждый<br>день</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white" style="color: #fff;">Нам доверяют клиенты<br>со всей России!</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/bank.png" alt="money"
                 style="position: absolute;
                 bottom: 0;
                 max-height: 75%;
                 right: 0;"
            >
          </div>
          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: var(--orange-50);
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">МКК «Колобок»</div>
              <div class="w-100">
                <h1 class="heading small">Разрабатываем современные технологии розничного кредитования, чтобы предоставлять быстрый и удобный доступ к финансовым ресурсам тем, кому это действительно нужно. </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ПРЕДОСТАВЛЯТЬ УДОБНЫЙ ДОСТУП -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container mb-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Наша миссия</div>
              <div class="w-100">
                <h1 class="heading">Предоставлять<br>удобный доступ<br>к займам для<br>миллионов<br>людей</h1>
              </div>
            </div>

            <div class="w-layout-vflex flex-good "
                 style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
            >
              <div class="w-layout-hflex flex-item-best pa-0">
                <a id="openLabel" @click="goToWriteUs" class="button-bust w-button">Сотрудничество</a>
              </div>

              <h2 class="text-mini white bold text-align-right">Используем новейшие<br>технологии</h2>
            </div>
          </div>

          <div id="w-node-ab6d8353-038f-d5eb-c2b5-ebdaefa956f0-8870dddc" class="item _2 grid-colum left w-100" style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="text-mini semibold">Лояльность</div>
            <h2 class="heading h2">Сотрудничество с Колобок - это:</h2>

            <div class="w-layout-hflex item-row gap-1">
                <div class="text-classic">
                  <img class="img-80" src="../../../../../public/images/helper/5.svg" alt="helper5">
                </div>
                <div class="w-layout-vflex item-col center gap-2">
                  <h2 class="heading h3">Прозрачные<br>и выгодные условия</h2>
                  <div class="text-mini semibold">Первый заём на 7 дней бесплатно и участие в программе лояльности</div>
                </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-80" src="../../../../../public/images/helper/4.svg" alt="helper4">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h3">Удобное распоряжение займом</h2>
                <div class="text-mini semibold">Перенесите срок возврата, если не успеваете погасить полностью</div>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-80" src="../../../../../public/images/helper/3.svg" alt="helper3">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h3">Не звоним родственникам и работодателю</h2>
                <div class="text-mini semibold">Они никогда не узнают, что вы обращались к нам</div>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-80" src="../../../../../public/images/helper/2.svg" alt="helper2">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h3">Безопасность</h2>
                <div class="text-mini semibold">Мы гарантируем защиту ваших данных и обеспечиваем для этого все технические и организационные меры безопасности</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- НАМ ДОВЕРЯЮТ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: #F3F3F3;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100 z-2">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="margin-bottom: 100px;">Нам доверяют<br>клиенты по всей<br>России</h1>
                  <h1 class="text-mini white">24 часа в сутки 7 дней в неделю<br>Колобок работает для вас</h1>
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/map.png" alt="map"
                   style="max-width: 100%; max-height: 90%; position: absolute; right: 30px; bottom: 0; z-index: 0;">

              <div class="w-layout-vflex flex-good direction-adaptive z-2"
                   style="
                       overflow: initial;
                       align-items: center;
                       flex-flow: row;
                       justify-content: space-between;"
              >
                <div>
                  <a id="openLabel" @click="openPersonalData" class="button-bust w-button">Оформить заявку</a>
                </div>
                <div class="w-layout-vflex flex-good"
                     style="flex-flow: row; justify-content: center;"
                >
                  <div>
                    <img height="30px" alt="visa" src="../../../../../public/images/about/visa.svg">
                  </div>
                  <div>
                    <img height="30px" alt="mir" src="../../../../../public/images/about/mir.svg">
                  </div>
                  <div>
                    <img height="30px" alt="mastercard" src="../../../../../public/images/about/mastercard.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- КОЛОБОК В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Колобок в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Main',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
  },
  computed: {
  },
  methods: {
    goToWriteUs() {
      this.$router.push('/write-to-us');
    },
    openPersonalData() {
      this.$router.push('/personal-data');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    }
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
